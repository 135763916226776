<template>
    <div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title m-0">
                    <h3 class="card-label m-0">
                        {{$t('cost_centers.cost_centers')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
                <div class="card-toolbar">
                    <!--begin::Button-->
<!--                    <router-link to="/accounting/cost-centers" class="btn btn-light font-weight-bolder">-->
<!--                        <v-icon>mdi-chevron-left</v-icon>-->
<!--                        {{$t('back')}}-->
<!--                    </router-link>-->
                    <!--end::Button-->
                </div>
            </div>

            <div class="card-body">
                <b-card-body class="p-0">
                    <div class="form-group row">
                        <div class="col-lg-6 mb-5">
                            <label>{{$t('cost_centers.name')}}<span class="text-danger">*</span></label>
                            <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
                            <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
                        </div>
                        <div class="col-lg-6 mb-5">
                            <label>{{$t('cost_centers.code')}}<span class="text-danger">*</span></label>
                            <input type="text" v-model="data.code" class="form-control" :class="validation && validation.code ? 'is-invalid' : ''"/>
                            <span v-if="validation && validation.code" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.code[0] }}
                            </span>
                        </div>
                        <div class="form-group col-md-6 mt-2 mb-2">
                            <label>{{$t('cost_centers.parent_cost_center')}}</label>
                            <treeselect
                                    :options="accounts_list"
                                    :load-options="loadOptions"
                                    :multiple="false"
                                    :value="data.parent_cost_center_id"
                                    @input="updateValue"
                                    :searchable="true"
                                    :class="validation && validation.parent_cost_center_id ? 'is-invalid' : ''"
                                    :show-count="true"
                                    :no-children-text="$t('No_sub_options')"
                                    :no-options-text="$t('No_options_available')"
                                    :no-results-text="$t('No_results_found')"
                                    :placeholder="$t('Select')">
                            </treeselect>
                            <span v-if="validation && validation.parent_cost_center_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.parent_cost_center_id[0] }}
                            </span>
                        </div>

                        <div class="col-lg-6 mb-5">
                            <label>&ensp;</label>
                            <b-form-checkbox size="lg" v-model="data.is_parent_cost_center" :class="validation && validation.is_parent_cost_center ? 'is-invalid' : ''" name="check-button" switch>{{$t('cost_centers.is_parent_cost_center')}}</b-form-checkbox>
                            <span v-if="validation && validation.is_parent_cost_center" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.is_parent_cost_center[0] }}
                            </span>
                        </div>
                    </div>

                </b-card-body>
                <div class="card-footer pl-0 pr-0">
                    <div class="row">
                        <div class="col-lg-6">
                            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "from-cost-centers",
        data() {
            return {
                mainRoute: 'accounting/cost-centers',
                mainRouteDependency: 'base/dependency',
                idEdit: this.$route.params.id ? this.$route.params.id : null,
                data: {
                    name: null,
                    code: null,
                    parent_cost_center_id: null,
                    is_parent_cost_center: false,
                },
                isEditing: false,
                show_message_confirm: false,
                validation: null,

                accounts_list: [],

            };
        },

        methods: {
            save() {
                if (this.isEditing) {
                    this.update();
                } else {
                    this.create();
                }
            },

            create() {
                ApiService.post(this.mainRoute, {
                    ...this.data,
                }).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                    this.$router.push('/accounting/cost-centers');
                }).catch((error) => {
                    this.$errorAlert(error);
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },

            update() {
                ApiService.put(this.mainRoute + '/' + this.idEdit, {
                    ...this.data,
                }).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                    this.$router.push('/accounting/cost-centers');
                }).catch((error) => {
                    this.$errorAlert(error);
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },
            
            
            getData() {
                ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
                    this.isEditing = true;
                    this.data.name = response.data.data.name;
                    this.data.code = response.data.data.code;
                    this.data.parent_cost_center_id = response.data.data.parent_cost_center_id;
                    this.data.is_parent_cost_center = response.data.data.is_parent_cost_center;
                });
            },
            updateValue(value) {
                this.data.parent_cost_center_id = value
            },
            loadOptions() {
            },
            getAccountParents() {
                ApiService.get(`${this.mainRouteDependency}/${'cost_centers'}`).then((response) => {
                    this.accounts_list = response.data.data;
                });
            },
            //custom_type_name_list
        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.cost_centers"), route:'/accounting/cost-centers'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

            this.getAccountParents();

            this.data.parent_cost_center_id = this.$route.params.parent_id ? this.$route.params.parent_id : null;

            if (this.idEdit) {
                this.getData();
            }

        },
    };
</script>


